/* eslint-disable prefer-destructuring */
export const NODE_ENV = process.env.NODE_ENV;
// Workaround: Parcel seems to pickup from .env.local even if not local.
const isLocal = NODE_ENV === 'local';
const isNonProd = NODE_ENV !== 'production';

export const ENV = process.env.ENV;
export const APP_VERSION = process.env.APP_VERSION;
export const PLATFORM = process.env.PLATFORM;
export const PLAYER_NAME = `${process.env.PLAYER_NAME}-${cast.framework.VERSION}`;
export const VENDOR = process.env.VENDOR;
export const STREAM_TYPE_DASH = 'dash';
export const STREAM_TYPE_LINEAR_CHANNEL = 'linear-channel';
export const DRM_NAME_WIDEVINE = 'widevine';
export const STREAM_PROVIDER_INFINIT = 'infinit';
export const LICENSE_NAME_WIDEVINE = 'widevine_modular';
export const LICENSE_NAME_PLAYREADY = 'playready';
export const WATCH_MODE_LIVE = 'live';
export const WATCH_MODE_START_OVER = 'startover';
export const WATCH_MODE_RESUME = 'resume';
export const WATCH_MODE_BEGINNING = 'beginning';
// milliseconds
export const STREAM_KICKING_INTERVAL = process.env.STREAM_KICKING_INTERVAL;
// milliseconds
export const SESSION_REFRESH_TIME_CHECK_BEFORE_EXPIRY =
  process.env.SESSION_REFRESH_TIME_CHECK_BEFORE_EXPIRY;
export const FETCH_TIMEOUT = process.env.FETCH_TIMEOUT;
// milliseconds
export const STALLED_TIMEOUT = 60000;
export const ANALYTICS_ID_CONVIVA = 'conviva-analytics';
export const ANALYTICS_ID_MUX = 'mux-analytics';
export const DR_MODE_ASSETS_CHROMECAST_URL =
  process.env.DR_MODE_ASSETS_CHROMECAST_URL;
export const DR_MODE_ASSETS_ANDROID_URL =
  process.env.DR_MODE_ASSETS_ANDROID_URL;
export const MESSAGE_BUS = 'urn:x-cast:au.com.optus.sport.media.chromecast';
export const LOGGER_LEVEL = process.env.LOGGER_LEVEL;
export const CONVIVA_TOUCHSTONE_SERVICE_URL =
  isNonProd && process.env.CONVIVA_TOUCHSTONE_SERVICE_URL;
export const CONVIVA_CUSTOMER_KEY = process.env.CONVIVA_CUSTOMER_KEY;
export const conviva = true;
export const MUX_CUSTOMER_KEY = process.env.MUX_CUSTOMER_KEY;
export const MUX_NAME = 'chromecastMux';
export const MUX_VERSION = '4.11.3';
export const mux = true;
export const muxDebug = false;
// TODO: Remove this when Playback API returns v2 by default.
export const SUPPORTS_50FPS = process.env.SUPPORTS_50FPS === 'true';
export const SEGMENT_KEY = process.env.SEGMENT_KEY;
export const VIDEO_PLAYER_NAME = 'shakaPlayer';
export const VIDEO_PLAYER_VERSION = '3.2.11';
export const ANALYTIC_DEFAULT_VENUE_MODE = 'standard';

export const apiUrls = {
  newML: process.env.BASE_API_URL,
  // TODO: DELETE THIS -- Not used in v2
  image: process.env.BASE_IMAGE_URL,
  optusNet: process.env.BASE_NETINFO_URL,
  sessionManager: process.env.BASE_API_SESSION_URL,
  refreshTokenUrl: process.env.BASE_API_REFRESH_TOKEN_URL,
  assetMetaDataUrl: process.env.BASE_API_ASSET_METADATA_URL,
  drModeAssetsChromecastUrl: process.env.DR_MODE_ASSETS_CHROMECAST_URL,
  drModeAssetsAndroidUrl: process.env.DR_MODE_ASSETS_ANDROID_URL,
  userDetails: process.env.BASE_API_USER_DETAILS_URL,
};

export const mimeTypes = {
  'application/vnd.ms-sstr+xml': 'ISM',
  'application/dash+xml': 'DASH',
  'application/vnd.apple.mpegurl': 'HLS',
};

// --- [Begin] Declaration for .env.local only for testing purposes ---
export const DR_MODE_SIMULATION =
  isLocal && process.env.DR_MODE_SIMULATION === 'true';
export const SENDER_EXPIRED_SIMULATION =
  isLocal && process.env.SENDER_EXPIRED_SIMULATION === 'true';
export const EXPIRED_TOKEN_ID = isLocal && process.env.EXPIRED_TOKEN_ID;
export const SIMULATE_ERROR = isLocal && process.env.SIMULATE_ERROR === 'true';
export const yspSdk = true;
export const yspSdkV3 = true;
export const yspSdkLive = process.env.YSPSDK_LIVE === 'true';
export const yspSdkLiveCmaf = process.env.YSPSDK_LIVE_CMAF === 'true';
export const adUiTreatment = true;
// Controls the maximum play session before considered as zombie and kicked (6hrs = 21600000ms)
export const maxPlaySessionTimeMs = 21600000;

// -- Manifest and Segment flag controls

// Controls whether to log out manifest every x interval milliseconds in native manifestHandler (setting to 0 will disable it)
export const manifestInterval = 0;

// Controls whether to log out manifest every x interval milliseconds in native manifestHandler if global manifestInterval is set to 0/disabled (this is for whitelisted users)
export const manifestIntervalUserId = 60000;

// Controls whether to log out manifest in native manifestHandler when buffering
export const manifestBuffering = false;

// Controls whether to log out manifest when custom stalled event is encountered
export const manifestCustomStalled = true;

// Controls whether to log out manifest when player encounters error event
export const manifestOnError = false;

// Controls whether to log out manifest in native manifestHandler when ad periods are encountered
export const manifestAd = false;

// White list of user ids that will log out manifest regardless of whether manifestInterval, manifestBuffering, manifestCustomStalled, manifestOnError, or manifestAd has been disabled
export const manifestUserIds = [];

// Controls what asset types will log out manifest >> 'live', 'l2v_live', 'vod'
export const manifestStreamTypesExcluded = ['l2v_live', 'vod'];

// Controls which watch modes will log out manifest >> 'live', 'startover', 'resume', 'beginning'
export const manifestWatchModesIncluded = ['live'];

// Controls whether the manifest contents are logged out
export const manifestRaw = true;

// Controls whether to store last "good" and immediate "bad" manifests >> good = player is not buffering, bad = player is buffering
export const manifestGoodBad = true;

// Controls whether to log out manifest's publish time and current client time when a manifest is processed
export const manifestTime = false;

// Controls whether to enabled sending data to segment/datadog or not
export const segmentEnabled = true;

// Controls whether to log out segment request in native segmentRequestHandler
export const segmentRequest = false;

// Controls whether to log out segment request when buffering encountered only in native segmentRequestHandler
export const segmentRequestBufferingOnly = true;

// White list of user ids that will log out segment request regardless of whether >> segmentRequest has been disabled
export const segmentRequestUserIds = [];

// Black list segment events that we do not want to send to datadog
export const segmentDisableConfig = [];

// Controls what asset types will log out segment >> 'live', 'l2v_live', 'vod'
export const segmentStreamTypesExcluded = ['l2v_live', 'vod'];

// Controls which watch modes will log out segment >> 'live', 'startover', 'resume', 'beginning'
export const segmentWatchModesIncluded = ['live'];

// Controls the amount of segment requests to be stored locally on the device
export const segmentStoreLimit = 100;

// Controls whether to log custom stalled event to TrackJS
export const trackJsCustomStalled = true;

// Devices that support cmaf by default
export const cmafSupportedDevices = ['Chromecast with Google TV'];
export const cmafCtrSupportedDevices = [
  'Chromecast Ultra',
  'Chromecast 3',
  'Chromecast 2',
];
export const cmaf = false;

// HEVC device qualities that force AVC
export const hevcAvcQuality = ['low'];

// HEVC device qualities that force HEVC
export const hevcHevcQuality = ['auto', 'high', 'medium'];

export const constantsConfig = {
  NODE_ENV,
  APP_VERSION,
  PLATFORM,
  PLAYER_NAME,
  VENDOR,
  STREAM_TYPE_DASH,
  STREAM_TYPE_LINEAR_CHANNEL,
  DRM_NAME_WIDEVINE,
  STREAM_PROVIDER_INFINIT,
  LICENSE_NAME_WIDEVINE,
  LICENSE_NAME_PLAYREADY,
  WATCH_MODE_LIVE,
  WATCH_MODE_START_OVER,
  WATCH_MODE_RESUME,
  WATCH_MODE_BEGINNING,
  STREAM_KICKING_INTERVAL,
  SESSION_REFRESH_TIME_CHECK_BEFORE_EXPIRY,
  FETCH_TIMEOUT,
  STALLED_TIMEOUT,
  ANALYTICS_ID_CONVIVA,
  DR_MODE_ASSETS_CHROMECAST_URL,
  DR_MODE_ASSETS_ANDROID_URL,
  MESSAGE_BUS,
  LOGGER_LEVEL,
  ANALYTIC_DEFAULT_VENUE_MODE,
  CONVIVA_TOUCHSTONE_SERVICE_URL,
  CONVIVA_CUSTOMER_KEY,
  MUX_CUSTOMER_KEY,
  MUX_NAME,
  MUX_VERSION,
  SUPPORTS_50FPS,
  SEGMENT_KEY,
  segmentEnabled,
  VIDEO_PLAYER_NAME,
  VIDEO_PLAYER_VERSION,
  apiUrls,
  mimeTypes,
  DR_MODE_SIMULATION,
  SENDER_EXPIRED_SIMULATION,
  EXPIRED_TOKEN_ID,
  SIMULATE_ERROR,
  conviva,
  yspSdk,
  yspSdkLive,
  yspSdkLiveCmaf,
  yspSdkV3,
  adUiTreatment,
  maxPlaySessionTimeMs,
  mux,
  muxDebug,
  manifestInterval,
  manifestIntervalUserId,
  manifestBuffering,
  manifestCustomStalled,
  manifestOnError,
  manifestAd,
  manifestUserIds,
  manifestStreamTypesExcluded,
  manifestWatchModesIncluded,
  manifestRaw,
  manifestGoodBad,
  manifestTime,
  segmentDisableConfig,
  segmentRequest,
  segmentRequestBufferingOnly,
  segmentRequestUserIds,
  segmentStreamTypesExcluded,
  segmentWatchModesIncluded,
  segmentStoreLimit,
  trackJsCustomStalled,
  cmafSupportedDevices,
  cmafCtrSupportedDevices,
  cmaf,
  hevcAvcQuality,
  hevcHevcQuality,
};
// --- [End]
