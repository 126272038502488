window.ClassHelper = (function () {
  function e() {}
  function t(t) {
    function i() {
      this.initialize.apply(this, arguments);
    }
    return (
      t &&
        ((e.prototype = t.prototype),
        (i.prototype = new e()),
        (e.prototype = {})),
      (i.prototype.constructor = i),
      (i._isConstructor = !0),
      i
    );
  }
  function i() {}
  function s(e) {
    var t, i, s, r, n, a;
    if ((t = 'function' == typeof e ? e.$super : e.callee.$super))
      if (1 == arguments.length) a = t.call(this);
      else {
        for (s = arguments.length - 1, i = new Array(s), r = 1, n = 0; n < s; )
          i[n++] = arguments[r++];
        a = t.apply(this, i);
      }
    return a;
  }
  function r(e) {
    var t, i, s;
    (t = []), (s = 0);
    for (i in e) t[s++] = i;
    return (
      n && void 0 !== e.toString && (t[s++] = 'toString'),
      a && void 0 !== e.valueOf && (t[s++] = 'valueOf'),
      t
    );
  }
  var n, a;
  return (
    (function () {
      var e;
      n = a = !0;
      for (e in { toString: !0, valueOf: !0 })
        'toString' == e && (n = !1), 'valueOf' == e && (a = !1);
    })(),
    {
      makeClass: function () {
        var e, n, a, o, l, h, u, d, g;
        for (
          'function' == typeof arguments[(n = 0)] &&
            arguments[n]._isConstructor &&
            (e = arguments[n++]),
            a = t(e);
          n < arguments.length;

        )
          for (
            'function' == typeof (o = arguments[n++]) && (o = o()),
              h = (l = r(o)).length - 1;
            h >= 0;
            --h
          )
            (d = o[(u = l[h])]),
              e &&
                'function' == typeof d &&
                !d._isMixinFunction &&
                'function' == typeof (g = e.prototype[u]) &&
                (d.$super = g),
              (a.prototype[u] = d);
        return (
          'initialize' in a.prototype || (a.prototype.initialize = i),
          (a.prototype.callSuper = s),
          a
        );
      },
      makeMixin: function () {
        var e, t, i, s, n;
        for (e = {}, t = 0; t < arguments.length; )
          for (
            'function' == typeof (i = arguments[t++]) && (i = i()),
              s = r(i),
              nameIndex = s.length - 1;
            nameIndex >= 0;
            --nameIndex
          )
            (name = s[nameIndex]),
              'function' == typeof (n = i[name]) && (n._isMixinFunction = !0),
              (e[name] = n);
        return e;
      }
    }
  );
})();

// console.log(ClassHelper);
