import EventTarget from '@ungap/event-target';
import { isCurrentBrowserSupported } from './uiUtils';
import '../vendors/yospace-sdk';

if (!isCurrentBrowserSupported()) {
  window.EventTarget = EventTarget;
}

// source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
if (!Object.entries) {
  Object.entries = function ObjectEntries(obj) {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    // eslint-disable-next-line no-plusplus
    while (i--) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }
    return resArray;
  };
}

// window.onerror = (message, source, lineno, colno, error) => {
//   log({
//     error: {
//       message,
//       source,
//       lineno,
//       colno,
//       error,
//     },
//   });
// };
